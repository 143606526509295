@import './Colors.scss';
@import './Generics.scss';

.radio_button-main_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.radio_button {
  margin: 5px;

  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 30px;
    border-radius: 8px;
    color: $color-primary;
    border: 1px solid $color-primary;
    cursor: pointer;

    &:hover {
      background-color: $color-gray;
    }
  }

  > input {
    display: none;

    &:checked + label {
      background-color: var(--color-primary-dark);
      color: $color-white;
      font-weight: 500;
    }
  }
}
