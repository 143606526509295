@import './Colors.scss';

.loading_line {
  height: 25px;
  margin: 5px 15px;
  width: calc(100% - 30px);
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    $color-gray_light,
    $color-gray,
    $color-gray_dark,
  );
  background-size: 300% 100%;
  animation: gradient .8s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 150% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}
