@import './Colors.scss';
@import './Generics.scss';

.notification {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 0px 20px;
  animation-timing-function: ease;
  animation-duration: 500ms;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

  &.displayAnimation {
    animation-name: notificationDisplay;
  }
  &.closeAnimation {
    animation-name: notificationClose;
  }

  &.error {
    background-color: $color-warning;
    color: $color-white;
  }

  &.info {
    background-color: var(--color-component);
    color: $color-white;
  }

  &-message {
    font-size: 16px;
    font-weight: bold;
    overflow: auto;
  }

  &-bar {
    height: 2px;
    width: 100%;
    animation: notificationBar 3s linear;
    background-color: $color-white;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    align-self: flex-end;
    transform-origin: center left;
    margin-top: 5px;
  }
}

@keyframes notificationDisplay {
  0% {
    top: -60px;
  }
  100% {
    top: 0px;
  }
}
@keyframes notificationClose {
  0% {
    top: 0px;
  }
  100% {
    top: -60px;
  }
}

@keyframes notificationBar {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@media only screen and (min-width: $desktop-media-width) {
  .notification {
    position: fixed;
    width: 400px;
    border-radius: 10px;
    overflow: hidden;
    top: 10px;
    display: flex;
    right: 10px;
  }

  @keyframes notificationDisplay {
    0% {
      right: -400px;
    }
    100% {
      right: 10px;
    }
  }
  @keyframes notificationClose {
    0% {
      right: 10px;
    }
    100% {
      right: -400px;
    }
  }
}
