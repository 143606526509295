@import './Colors.scss';
@import './Generics.scss';

.notification-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification-title {
  margin: 10px;
  color: $color-primary;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.notification-description {
  color: $color-primary;
  font-size: 16px;
  margin: 10px 0px;
}

.notification-image {
  border-radius: 10px;
  margin: 10px;
  &-small {
    max-width: 170px;
    max-height: 170px;
  }
  &-medium {
    max-width: 250px;
    max-height: 200px;
  }
  &-large {
    max-width: 100%;
    max-height: 80%;
  }
}

.notification-icon {
  width: 50%;
  height: auto;
  color: $color-secondary;
}

.notification-button {
  font-size: 18px;
  color: $color-white;
  height: 40px;
  padding: 0px 10px;
  background-color: $color-secondary;
}
