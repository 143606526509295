@import './Colors.scss';
@import './Generics.scss';

.home_page {
  padding: 130px 20px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  &-desktop_header {
    width: 100%;
    height: 60px;
    background-color: var(--color-primary-dark);
    color: $color-white;
    position: fixed;
    display: flex;
    padding: 0px 20px;
    align-items: center;
    top: 0;
    &--logo {
      display: flex;
      align-items: baseline;
      & > span {
        font-weight: 500;
        & > mark {
          border-radius: 4px;
          background-color: $color-secondary;
          padding: 0px 3px;
        }
      }
    }
  }
  &-search {
    flex: 1;
    display: flex;
    width: 100%;
    margin-top: 15px;
    height: 35px;
    align-items: center;
    justify-content: flex-end;
    font-size: 25px;
    & > svg {
      pointer-events: none;
      color: var(--color-component);
    }

    & > button {
      color: $color-gray_dark;
    }

    & > input {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      padding: 0px 10px;
      margin-left: 10px;
      background-color: $color-white;
      line-height: 25px;
      font-size: 16px;
      color: $color-primary;
      border: 1px solid $color-gray;
      -webkit-appearance: none;

      &::-moz-placeholder {
        color: $color-gray_dark;
      }

      &::placeholder {
        color: $color-gray_dark;
      }
      &:focus {
        outline: none;
        border-color: $color-primary;
      }
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
  &-section-separator {
    grid-column: 1 / -1;
    margin-top: 14px;
    width: 100%;
    color: $color-primary;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 3px solid var(--color-component-light);
    border-radius: 1px;
    padding-bottom: 5px;
  }

  &-bar_button {
    display: flex;
    font-size: 35px;
    align-items: center;
    height: 100%;
    width: 100%;
    font-weight: 500;

    &--icon {
      font-size: 30px;
    }

    &--items {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: relative;
      float: right;
      top: 6px;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      background-color: $color-red_notification;
      color: $color-white;
    }

    & > svg {
      font-size: 70px;
    }

    &--bar {
      width: 0.1em;
      height: 60%;
      background-color: $color-white;
    }

    &--label {
      color: $color-white;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
    }

    span {
      color: $color-white;
      font-size: 18px;
      width: 100%;
    }
  }
}

@media only screen and (min-width: $desktop-media-width) {
  .home_page {
    padding: 60px 20px 30px 180px;
    border-left: 330px solid var(--color-component-light);
    min-height: 100vh;
    &-search {
      justify-content: flex-end;
      position: relative;
      margin-top: 0px;

      & > svg {
        position: absolute;
        right: 220px;
      }

      & > button {
        position: absolute;
        right: 0px;
      }
      & > input {
        width: 250px;
        padding: 0px 40px;
      }
    }
  }
}

@media only screen and (min-width: $tablet-media-width) and (max-width: $desktop-media-width) {
  .home_page {
    &-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (min-width: $desktop-media-width) and (max-width: $large_desktop-media-width) {
  .home_page {
    &-wrapper {
      grid-template-columns: 1fr;
    }
  }
}

@media only screen and (min-width: $large_desktop-media-width) and (max-width: $ultra_large_desktop-media-width) {
  .home_page {
    &-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (min-width: $ultra_large_desktop-media-width) {
  .home_page {
    &-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
