@import './Colors.scss';
@import './Generics.scss';

.submit_button {
  padding: 5px 20px;
  background-color: var(--color-component);
  border: none;
  color: $color-white;
  margin: 10px 0;

  &[disabled] {
    background-color: $color-gray;
  }

  &.full {
    width: 100%;
  }

  & > svg {
    font-size: 20px;
    color: $color-white;
  }

  & > div {
    font-size: 14px;
  }
}

.rounded_rectangle_button {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &-label {
    font-size: 16px;
    font-weight: bold;
    margin: 5px;
  }

  &[disabled] {
    color: $color-gray_light;
    cursor: not-allowed;
    border: none;
    background-color: $color-gray_dark;
  }

  &:not([disabled]) {
    &:active {
      animation: splash 200ms ease-in;
    }
    &:hover {
      background-color: $color-primary-light;
    }
  }
}

@keyframes splash {
  0% {
    box-shadow: 0px 0px 0px 0px transparentize($color-primary, 0.5);
  }
  30% {
    box-shadow: 0px 0px 4px 3px transparentize($color-primary, 0.5);
  }
  100% {
    box-shadow: 0px 0px 0px 0px transparentize($color-primary, 0.5);
  }
}
