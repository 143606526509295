@import './Colors.scss';
@import './Generics.scss';

.cart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cart-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  color: $color-primary;
  font-size: $x-large;
  p {
    margin: 0px 10px;
    font-weight: bold;
  }
}

.cart-content {
  color: $color-primary;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
  overflow-y: auto;
  overscroll-behavior: none;
}

.cart-directions {
  width: 90%;
  p.default-text {
    font-size: 14px;
    font-weight: bold;
  }
}

.cart-item {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  width: 100%;

  &::before {
    content: '';
    width: 14px;
    height: 11px;
    border-radius: 50%;
    margin-top: 3px;
    background-color: $color-primary;
    margin-right: 10px;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
  }

  &-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 5px;
  }
  &-buttons {
    display: flex;
    flex-direction: column;
  }
}

.cart-list {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  &-item {
    font-size: 14px;
    display: flex;
    &::before {
      content: '•';
      margin-right: 5px;
    }
    &--label {
      flex: 1;
    }
  }
}
.cart-button-edit,
.cart-button-delete {
  height: 30px;
  width: 60px;
  font-size: 20px;
}
.cart-button-edit {
  color: $color-white;
  background-color: $color-primary;
  margin-bottom: 5px;
}

.cart-button-delete {
  color: $color-red_notification;
  border: 1px solid;
  background-color: transparent;
}

@media only screen and (min-width: $desktop-media-width) {
  .cart-container {
    padding: 0px 20px;
    height: calc(100% - 120px);
  }
  .cart-item {
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0px;
    background-color: $color-gray_light;
  }
}
