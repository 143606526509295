@import './Colors.scss';

.online_payment_page {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  overscroll-behavior: none;
  align-items: center;
  padding-top: 20px;

  &-title {
    color: $color-primary;
    font-weight: bold;
    padding-left: 20px;
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.payment-iframe {
  width: 100%;
  height: 100%;
  min-height: 80vh;

  &-banner {
    display: flex;
    padding: 10px 20px 10px 10px;
    margin: 10px 10px;
    color: $color-white;
    background-color: $color-secondary;
    border-radius: 10px;
  }

  &-progress {
    padding-right: 5px;
    font-size: xx-large;
  }
}
