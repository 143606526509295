@import './Colors.scss';
@import './Generics.scss';

.bar_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  padding: 0px 20px;
  height: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.4);
  color: $color-white;
  background-color: var(--color-component);
  cursor: pointer;

  &-main_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
  }

  &:hover {
    background: var(--color-component-light);
  }
  &.disabled {
    background: $color-gray_dark;
    box-shadow: none;
  }
  &.animateDisabled {
    animation: disabledAnimation 500ms ease;
  }
}

@keyframes disabledAnimation {
  50% {
    background: $color-red_notification;
  }
}

@media only screen and (min-width: $desktop-media-width) {
  .bar_button {
    background: var(--color-component);
    &-main_div {
      position: absolute;
    }
  }
}
