@import './Colors.scss';

.flat_button-main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 35px;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:hover {
    color: $color-gray;
  }
}
