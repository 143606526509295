@import './Colors.scss';

.select {
  width: 100%;
  cursor: pointer;
  text-align: center;
  width: 100%;
  line-height: 25px;
  font-size: 16px;
  margin-top: 5px;
  height: 40px;
  border-radius: 8px;
  color: $color-primary;
  background: $color-white;
  border: 1px solid $color-gray;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;

  &.open {
    border: 1px solid $color-primary;
  }

  &-input {
    font-size: 16px;
    background: none;
    width: 100%;
    height: 41px;
    padding: 8px 16px;
  }

  &-header {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
  }

  &-items {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
    width: 100%;
    line-height: 25px;
    margin-top: 5px;
    border-radius: 8px;
    color: $color-primary;
    border: 1px solid $color-gray;
    background: $color-white;
    max-height: 320px;
    overflow: hidden;
    overflow-y: scroll;
    box-shadow: 0px 4px 10px 2px $color-gray;
    animation: open-list 100ms ease-out;

    &.close {
      animation: close-list 100ms ease-in;
    }
  }

  &-item {
    align-items: center;
    cursor: pointer;
    text-align: center;
    width: 100%;
    padding: 8px 16px;
    color: $color-primary;
    border-bottom: 1px solid $color-gray;
    &.current {
      background-color: transparentize($color-gray_light, 0.3);
    }
    &:hover {
      background-color: transparentize($color-gray_light, 0.7);
    }
  }

  &-label {
    overflow: hidden;
    width: 100%;
    word-wrap: inherit;
    text-overflow: ellipsis;
    white-space: pre;
  }
}

@keyframes open-list {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes close-list {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.container-countries{
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 10px;

  &__select{
    width: 45%;
    margin-top: 1px;
    &-label{
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
    }
    &-control{
      height: 43px;
    }
    &-format-option{
      width: 100%;
      &__country_default {
        margin-bottom: 10px;
        &-flag{
          width: 25px;
          margin-right: 10px;
        }
      }
      &__country {
        line-height: 10px;
        &-flag{
          width: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  &__phone{
    width: 55%;
  }
}