@import './Colors.scss';

.product_card {
  height: 140px;
  min-height: 140px;
  width: 100%;
  max-width: 450px;
  padding: 5px;
  margin: 8px 5px;
  display: flex;
  align-items: center;
  color: $color-primary;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: pointer;
  border-bottom: 2px solid $color-gray;

  &:hover {
    background-color: $color-gray_light;
  }

  &-image {
    background-color: $color-white;
    height: 130px;
    min-height: 130px;
    width: 130px;
    min-width: 130px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 5px;
    &.default {
      background-color: $color-gray_light;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $color-gray;
      font-size: 30px;
      & > span {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  &-item {
    display: grid;
    padding: 0px 10px;
    height: 100%;
    width: 100%;
    align-items: center;
    grid-template-rows: 1fr 1.5fr 1fr;

    &-title {
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-description {
      font-size: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
    &-price {
      font-size: 22px;
      font-weight: bold;
      text-align: right;
    }
  }
}

