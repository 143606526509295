@import './Colors.scss';

.status_label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;

  &-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    box-shadow: 0px 0px 4px 0px;

    &.closed {
      background-color: $color-red_notification;
      color: $color-red_notification;
    }

    &.open {
      background-color: $color-green;
      color: $color-green;
    }
  }

  &-label {
    font-weight: 500;
  }
}
