@import './Colors.scss';

.textInf {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: $color-primary_dark;
  width: 80%;
  margin: 10px auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  float: left;
  position: relative;
  font-size: 1rem;
}
.text span {
  position: absolute;
  right: 0;
  width: 0;
  background-color: $color-white;
  border-left: 1px solid $color-primary_dark;

  animation: maquina 2s infinite alternate steps(27);
}

@keyframes maquina {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

button.big-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 0.9rem;
  color: $color-secondary;
  font-weight: 700;
  text-transform: uppercase;
  font-family: inherit;
  width: 80%;
  margin: 80px auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.big-button {
  padding: 1em 2em;
  border: 2px solid $color-secondary;
  border-radius: 1em;
  background: $color-gray_light;
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}
button.big-button::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-primary_dark;
  border-radius: inherit;
  box-shadow: 0 0 0 2px $color-primary, 0 0.75em 0 0 $color-secondary;
  transform: translate3d(0, 0.75em, -1em);
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

button.big-button:hover {
  background: $color-gray;
  transform: translate(0, 0.375em);
}

button.big-button:hover::before {
  transform: translate3d(0, 0.75em, -1em);
}

button.big-button:active {
  transform: translate(0em, 0.75em);
}

button.big-button:active::before {
  transform: translate3d(0, 0, -1em);

  box-shadow: 0 0 0 2px $color-primary, 0 0.25em 0 0 $color-primary;
}

.fundo {
  animation: scales 3s alternate infinite;
  transform-origin: center;
}
.pao-baixo {
  animation: rotatepao 14s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
  transform-origin: center;
}
.pao-cima {
  animation: rotatepao 7s 1s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
  transform-origin: center;
}
.olhos {
  animation: olhos 2s alternate infinite;
  transform-origin: center;
}

.left-sparks {
  animation: left-sparks 4s alternate infinite;
  transform-origin: 150px 156px;
}

.right-sparks {
  animation: left-sparks 4s alternate infinite;
  transform-origin: 310px 150px;
}

.olhos {
  animation: olhos 2s alternate infinite;
  transform-origin: center;
}
@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rotatepao {
  0% {
    transform: rotate(0deg);
  }
  50%,
  60% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes olhos {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(30deg);
  }
}

@keyframes left-sparks {
  0% {
    opacity: 0;
  }
}

.not_found {
  height: calc(80vh - 70px);
  width: 100%;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  font-size: 20px;
}

.main {
  margin: 0px auto;
  width: auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: dash 4s alternate infinite;
}

@keyframes dash {
  0%,
  30% {
    fill: #4b4b62;
    stroke-dashoffset: 0;
  }
  80%,
  100% {
    fill: transparent;
    stroke-dashoffset: -200;
  }
}
