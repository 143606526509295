@import 'Styles/Colors.scss';

.map {
  &-wrapper {
    padding: 0 5px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $color-primary_dark;
    margin-top: 30px;
    margin-left: 70px;
  }
  &-description {
    font-size: 16px;
    line-height: 22px;
    color: $color-primary_dark;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }
  &-bar_button {
    display: flex;
    align-items: center;

    span {
      font-size: 18px;
      line-height: 22px;
    }

    svg {
      width: 25px;
      height: 30px;
    }
  }
  &-autocomplete {
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 10px 10px 0 10px;
    input {
      border-radius: 5px;
      font-size: 14px;
      width: 100%;
      padding: 9px 10px;
      outline: none;
      border: 1px solid $color-primary_dark;
    }
  }
}
