@import './Colors.scss';
@import './Generics.scss';

.page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-white;
  overscroll-behavior: none;
  animation-name: openPage;
  animation-duration: 200ms;
  animation-timing-function: ease-in;

  &.closed {
    animation-name: closePage;
    opacity: 0;
    & button {
      pointer-events: none;
    }
  }

  &-back_button {
    font-size: 45px;
    cursor: pointer;
    position: absolute;
    left: 20px;
    background-color: $color-white;
    top: 20px;
    z-index: 1;
    box-shadow: -5px 0px 3px 0px $color-gray_light;
  }

  &-backdrop {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    overscroll-behavior: none;
    background-color: transparentize($color-gray_dark, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
  }
}

@keyframes openPage {
  0% {
    transform: translate(-50px, 0);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes closePage {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
}

@media only screen and (min-width: $desktop-media-width) {
  .page {
    right: 0px;
    width: 400px;
    &-backdrop {
      display: block;
    }
    &-back_button {
      right: 380px;
      left: auto;
      left: initial;
      background-color: var(--color-component);
      color: $color-white;
      box-shadow: 0px 5px 8px 0px $color-gray_dark;
      &:hover {
        background-color: $color-gray;
        color: $color-white;
      }
    }
  }
}
