@import './Colors.scss';
@import './Generics.scss';

.text-area {
  background-color: $color-white;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 5px 16px;
  resize: none;
  font-size: 16px;
  border: 1px solid $color-gray;
  transition: border 0.3s ease;
  -webkit-appearance: none;
  &:focus {
    outline: none;
    border-color: $color-primary;
  }
  &::-moz-placeholder {
    text-align: center;
    color: $color-gray_dark;
  }
  &::placeholder {
    text-align: center;
    color: $color-gray_dark;
  }
  &:focus {
    outline: none;
    border-color: $color-primary;
  }
}

