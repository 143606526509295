$color-white: #ffff;
$color-secondary: #4298b5;
$color-primary: #253746;
$color-primary_dark: #18242e;
$color-primary_light: #3d5a73;
$color-gray: #bfd3d9;
$color-gray_dark: #adb6b9;
$color-gray_medium: #c0c0c0;
$color-gray_light: #e5ecee;
$color-warning: #d54c4c;
$color-red_notification: #f91515;
$color-green: #028d46;
$color-green_light: #2fc12f;
$color-component: #4298b5;
$color-component-dark: #18242e;
$color-component-light: #3d5a73;

:root {
  --color-white: #{$color-white};
  --color-secondary: #{$color-secondary};
  --color-primary: #{$color-primary};
  --color-primary-dark: #{$color-primary-dark};
  --color-primary-light: #{$color-primary-light};
  --color-gray: #{$color-gray};
  --color-gray-dark: #{$color-gray-dark};
  --color-gray-medium: #{$color-gray-medium};
  --color-gray-light: #{$color-gray-light};
  --color-warning: #{$color-warning};
  --color-red-notification: #{$color-red-notification};
  --color-green: #{$color-green};
  --color-green-light: #{$color-green-light};
  --color-component: #{$color-component};
  --color-component-dark: #{$color-component-dark};
  --color-component-light: #{$color-component-light};
}
