@import './Colors.scss';

.product_page {
  display: flex;
  padding: 20px 20px 120px 20px;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overscroll-behavior: none;
  width: 100%;
  & > * {
    flex-shrink: 0;
    margin: 5px 0px;
  }

  &-share_button {
    color: $color-white;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    font-size: 20px;
    width: 60px;
    align-self: flex-end;
    background-color: var(--color-component);
    z-index: 1;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  padding-top: 4rem;
  padding-bottom: 4rem;
  cursor: zoom-in;
  margin: 50px 0;
  position: relative;
  z-index: 10;
}

.image-container.open {
  cursor: zoom-out;
}

.image-container .shade {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}

.image-container.open .shade {
  display: block;
  opacity: 1;
}

.image-container img {
  position: absolute;
  width: 14rem;
  height: 14rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}

.image-container.open img {
  position: fixed;
  top: 0;
  bottom: 128px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  width: auto;
  height: auto;
}

  .product_page-image.default {
    background-color: $color-gray_light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $color-gray;
    font-size: 30px; 
    width: 12rem;
    height: 12rem;
    border-radius: 8px;
  }
   
  .product_page-image.default > span {
      font-weight: bold;
      font-size: 14px; 
    }



.product_page-description {
  color: var(--color-primary-dark);
  text-align: center;
  font-size: 14px;
  margin: 0px 30px;
}

.product_page-suggestion-title {
  color: var(--color-primary-dark);
  font-size: 16px;
  font-weight: bold;
}

.product_page-bar_button {
  display: flex;
  font-size: 35px;
  align-items: center;
  height: 100%;

  span {
    color: $color-white;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
  }
}

