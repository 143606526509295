@import './Colors.scss';

.box-location {
  text-align: center;
}

.box-location_address {
  margin: 10px;
  font-size: 14px;
}

.buttonLoc {
  display: flex;
  align-items: flex-end;
  color: var(--color-component);
  -webkit-text-decoration: underline;
  text-decoration: underline;
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  -webkit-text-decoration: none;
  text-decoration: none;
  justify-content: center;
  &-icon {
    margin-left: 5px;
    color: var(--color-component);
    font-size: 25px;
  }
}

