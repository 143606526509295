@import 'Styles/Colors.scss';
@import 'Styles/Generics.scss';

.div_cover-item {
  margin-bottom: 12px;
}
.cover_item {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
  width: 355px;
  height: 162px;
}

.div_name-commerce {
  display: flex;
  color: $color-primary;
  font-weight: bold;
  font-size: $x-medium;
  text-align: left;
}

.div_data-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.div_name-status {
  display: flex;
  gap: 14px;
  margin: 2px;
  margin-bottom: 12px;
}

.div_horarios-item {
  display: flex;
}

.commerce_address {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.logo_item {
  border-radius: 50%;
}

.multivendor_item,
.branch_office_item {
  display: flex;
  flex-direction: column;
  background-color: $color-gray-light;
  color: $color-primary;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 0 0 15px 0;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;

  &_buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;

    button {
      flex: 1;
      padding: 8px 12px;
      font-size: 1rem;
      font-weight: bold;
      color: $color-primary;
      background-color: $color-gray-light;
      border: 2px solid $color-primary;
      border-radius: 8px;
      cursor: pointer;
      transition:
        background-color 0.3s,
        color 0.3s;

      &:hover {
        background-color: $color-gray-dark;
      }
    }

    .primary {
      background-color: $color-primary;
      color: $color-white;
      border: none;

      &:hover {
        background-color: $color-primary-light;
        color: $color-white;
      }
    }
  }

  @media (min-width: 1024px) {
    margin: 8px;
  }

  @media (min-width: 720px) {
    &:hover {
      background-color: $color-white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
  }

  &_header {
    display: flex;
    align-items: center;
  }

  &_body {
    display: flex;
    align-items: center;
    margin: 6px 0 8px 0;
  }

  &_text {
    color: $color-primary;
    font-weight: bold;
    font-size: $x-medium;
    text-align: center;
  }

  &_schedule {
    font-size: 0.875rem;
  }

  &_hour {
    font-size: 0.875rem;
    margin-left: 5px;
  }

  &_aprox {
    display: flex;
    padding-bottom: 10px;
    font-size: 0.875rem;

    &_text1 {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  &_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    color: $color-white;
    font-size: 0.9rem;
    text-align: center;
    font-weight: bold;
    padding: 2px 4px 2px 4px;
    padding-bottom: 12px;
  }

  &_tags_name {
    border-radius: 4px;
    padding: 6px;
    background-color: $color-primary_dark;
  }

  &_menu {
    color: $color-white;
    font-size: 0.9rem;
    text-align: center;
    font-weight: bold;
    background-color: $color-secondary;
    padding: 8px 18px;
    border-radius: 8px;
  }
}

.multivendor_item {
  cursor: default;
}
