@import 'Styles/Colors.scss';
@import 'Styles/Generics.scss';

.branch_office_item {
  display: flex;
  flex-direction: column;
  background-color: $color-gray-light;
  color: $color-primary;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 0 0 15px 0;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;

  img.cover_item {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 12px;
    height: 162px;
  }

  .primary {
    background-color: $color-primary;
    color: $color-white;
    border: none;

    &:hover {
      background-color: $color-primary-light;
      color: $color-white;
    }
  }

  @media (min-width: 1024px) {
    margin: 8px;
  }

  @media (min-width: 720px) {
    &:hover {
      background-color: $color-white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
  }

  &_header {
    display: flex;
    align-items: center;
  }

  &_body {
    display: flex;
    align-items: center;
    margin: 6px 0 8px 0;
  }

  &_text {
    color: $color-primary;
    font-weight: bold;
    font-size: $x-medium;
    text-align: left;
  }

  &_schedule {
    font-size: 0.875rem;
  }

  &_hour {
    font-size: 0.875rem;
    margin-left: 5px;
  }

  &_address {
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  &_aprox {
    display: flex;
    padding-bottom: 10px;
    font-size: 0.875rem;

    &_text1 {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  &_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    color: $color-primary;
    font-size: 0.9rem;
    text-align: center;
    font-weight: bold;
    padding: 2px 4px 2px 4px;
    padding-bottom: 12px;
  }

  &_tags_name {
    border-radius: 4px;
    padding: 6px;
    background-color: #c4c4c4;
  }

  &_menu {
    color: $color-white;
    font-size: 0.9rem;
    text-align: center;
    font-weight: bold;
    background-color: $color-secondary;
    padding: 8px 18px;
    border-radius: 8px;
  }
}
