@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import './assets/css/Colors.scss';

html {
  -webkit-font-smoothing: antialiased;
}

* {
  border: none;
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  font-family:
    'Montserrat',
    Arial sans-serif;
}

ul {
  list-style-position: inside;
}

.loading_page {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
  background-color: #253746;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  align-items: center;
  opacity: 1;
  transition: opacity 500ms ease 500ms;
}

.loading_div {
  animation: opacity 500ms ease 2s forwards;
  opacity: 0;
  margin-top: 20px;
}

.loading_wheel {
  animation: rotation 1s infinite linear;
  border: 4px solid transparentize($color: $color-secondary, $amount: 0.5);
  border-left: 4px solid $color-white;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button_full {
  width: 100%;
}
