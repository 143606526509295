@import './Colors.scss';
@import './Generics.scss';

.header {
  display: grid;
  grid-template-columns: 2fr 4fr 50px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $color-white;
  color: $color-primary;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.25);

  &-icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-id_card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  &-logo {
    height: 80px;
    width: auto;
    max-width: 95%;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 10px;
  }

  &-logo_container {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &-title {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    height: 2em;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-info {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 6px;
  }

  &-info_button {
    align-self: center;
    justify-self: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;

    & > svg {
      color: var(--color-component);
    }

    &--label {
      font-size: 14px;
      font-weight: 500;
      margin-left: 3px;
    }
  }
}

@media only screen and (min-width: $desktop-media-width) {
  .header {
    display: grid;
    padding-top: 30px;
    grid-template-rows: 150px 1fr;
    align-items: start;
    left: 100px;
    width: 380px;
    top: 10%;
    max-height: 80%;
    border-radius: 15px;
    box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.25);
    &-info {
      margin-top: 10px;
      flex-direction: column;
      align-content: center;
    }
    &-info_button {
      grid-column: 2 / 4;
      border-radius: 10px;
      color: var(--color-primary-dark);
      background-color: transparent;
      border: 2px solid;
      height: 40px;
      width: 75%;
      margin-top: 10px;

      & > svg {
        color: var(--color-component);
      }
      &:hover {
        background-color: $color-gray;
      }
      &:active {
        transform: translateY(2px);
        box-shadow: 0px 0px 0px 0px transparent;
      }
      &--label {
        font-weight: bold;
        margin-left: 0px;
      }
    }
    &-logo_container {
      position: absolute;
      left: -70px;
      top: 10px;
      background-color: $color-white;
      height: 180px;
      width: 180px;
      border: 16px solid var(--color-component);
      border-radius: 50%;
      box-shadow: 13px 10px 10px 0px rgba(0, 0, 0, 0.15);
      z-index: 1;
    }
    &-logo {
      height: auto;
      width: auto;
      max-height: 100px;
      max-width: 100px;
      max-width: 95%;
      border-radius: 10px;
    }
    &-desktop_bar_button {
      width: 95%;
      display: flex;
      grid-column: 1 / 4;
      border-radius: 15px;
      padding: 0px 20px;
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 56px;
      justify-self: center;
      color: $color-white;
      background-color: var(--color-component);
      font-size: 35px;
      font-weight: 500;

      &:hover {
        background-color: var(--color-component-light);
      }

      &--icon {
        font-size: 30px;
      }

      &--bar {
        width: 0.1em;
        height: 60%;
        background-color: $color-white;
      }

      &--items {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: relative;
        float: right;
        top: 6px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        background-color: $color-red_notification;
        color: $color-white;
      }

      & > svg {
        font-size: 70px;
      }

      &--label {
        font-size: 18px;
        font-weight: 500;
        width: 100%;
      }

      span {
        font-size: 18px;
        width: 100%;
      }
    }
  }
}
