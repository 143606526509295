@import './Colors.scss';

.string_input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 16px;
  margin-top: 5px;
  border-radius: 8px;
  color: $color-primary;
  border: 1px solid $color-gray;
  background: $color-white;
  transition: border 0.3s ease;
  -webkit-appearance: none;
  &::-moz-placeholder {
    text-align: center;
    color: $color-gray_dark;
  }
  &::placeholder {
    text-align: center;
    color: $color-gray_dark;
  }
  &:focus {
    outline: none;
    border-color: $color-primary;
  }
  &-container {
    width: 100%;
  }

  &-title {
    font-size: 14px;
  }
  &:disabled {
    color: $color-gray_dark;
  }
}
