@import './Colors.scss';

.count_buttons {
  display: flex;
  align-items: center;

  span {
    margin: 0px 5px;
    min-width: 10px;
    text-align: center;
  }

  &-minus,
  &-plus {
    height: 25px;
    width: 40px;
    margin: 2px;
    font-size: 20px;
    background-color: transparent;
  }

  &-minus {
    color: $color-primary-dark;
    border: 1px solid;
  }
  &-plus {
    color: $color-white;
    background-color: var(--color-primary-dark);
  }
}
