$x-small: 0.7rem;
$x-medium: 1rem;
$x-medium2: 1.2rem;
$x-large: 1.5rem;

$ultra_large_desktop-media-width: 1770px;
$large_desktop-media-width: 1200px;
$desktop-media-width: 992px;
$tablet-media-width: 768px;

