@import './Colors.scss';

.delivery_rider {
  &-loading-item {
    background-color: $color-gray_light;
    height: 12px;
    border-radius: 6px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      border-radius: 46px;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        to right,
        $color-gray_light 0%,
        rgba(0, 0, 0, 0.05) 20%,
        $color-gray_light 40%,
        $color-gray_light 100%
      );
      background-repeat: no-repeat;
      background-size: 750px 100%;
      animation: shimmer 2s linear infinite;
    }
  }
  &-title {
    color: $color-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 13px;
    margin-top: -10px;
  }
  &-data {
    display: flex;
    align-items: center;

    &-image {
      width: 55px;
      height: 55px;

      > * {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &-details {
      flex: 1;
      font-size: 15px;
      font-style: normal;
      line-height: 14px;
      letter-spacing: -0.154px;
      display: flex;
      flex-direction: column;
      row-gap: 7px;
      margin-left: 10px;

      &-name {
        color: var(--color-component);
        font-weight: 600;
      }
      &-id {
        color: $color-primary;
        font-weight: 400;
      }
      &-whatsapp {
        display: flex;
        align-items: center;
        color: var(--color-primary-dark);
        font-weight: 600;
        cursor: pointer;

        span {
          margin-right: 3px;
          display: flex;
          align-items: center;
          color: var(--color-component);
        }
      }
    }
  }
  &.disabled {
    box-shadow: none;
  }
}

@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }

  100% {
    background-position: 450px 0;
  }
}
