@import './Colors.scss';

.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain {
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.b-contain input[type='checkbox'] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: $color-gray_light;
  transition: background 250ms;
  border: 3px solid var(--color-primary-dark);
  border-radius: 0.824rem;
}

.b-contain input[type='radio'] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: $color-gray_light;
  transition: background 250ms;
  border: 3px solid var(--color-primary-dark);
  border-radius: 2rem;
}

.b-contain input[type='checkbox'] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: 0.45rem;
  top: 0.18rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid $color-white;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input[type='radio'] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: 0.25rem;
  top: 0.25rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 2rem;
  background: $color-white;
  transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
  border-color: $color-gray;
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
  background: $color-gray_light;
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 1px rgba(52, 144, 220, 0.5);
}

.b-contain input:checked ~ .b-input {
  background: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.b-contain input[type='checkbox']:disabled ~ .b-input {
  background: $color-gray_light;
  border-color: $color-gray;
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input[type='radio']:disabled ~ .b-input {
  background: $color-gray_light;
  border-color: $color-gray;
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input[type='radio']:disabled ~ .b-input::after {
  background: $color-gray;
}

.b-contain input:checked:focus ~ .b-input,
.b-contain:hover input:not([disabled]):checked ~ .b-input {
  background: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.b-contain .b-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: $color-secondary;
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.b-contain input + .b-input::before {
  animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
  animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
  visibility: hidden;
}

.b-contain input:focus + .b-input::before {
  visibility: visible;
}

.b-contain:first-child .b-input::before {
  visibility: hidden;
}
