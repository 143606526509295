@import './Colors.scss';
@import './Generics.scss';

.dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 30px;
  overflow: hidden;
  overscroll-behavior: none;
  background-color: transparentize($color-gray_dark, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(10px);
  animation-duration: 200ms;
  animation-timing-function: ease-in;

  &-wrapper {
    position: relative;
    max-height: 100%;
    display: flex;
    max-width: 100%;
    min-width: 80vw;
    flex-shrink: 0;

    &.width-auto {
      min-width: 0;
    }
    
  }

  &.openDialog {
    animation-name: openScreen;
    .dialog-content {
      animation-name: openDialog;
    }
    .dialog-close_button {
      animation-name: openDialogButton;
    }
  }

  &.closeDialog {
    opacity: 0;
    animation-name: closeScreen;
    .dialog-content {
      animation-name: closeDialog;
    }
    .dialog-close_button {
      animation-name: closeDialogButton;
    }
  }

  &-content {
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 10px;
    background-color: $color-white;
    box-shadow: 0px 0px 30px 0px black;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
  }

  &-close_button {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-component);
    color: $color-white;
    box-shadow: 0px 0px 10px 0px black;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
  }
}

@keyframes openDialog {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes closeDialog {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes openDialogButton {
  0% {
    transform: translate(-40vw, 150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes closeDialogButton {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-40vw, 150px);
  }
}

@keyframes openScreen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closeScreen {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (min-width: $desktop-media-width) {
  .dialog-wrapper {
    width: auto;
  }
}
