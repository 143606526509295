@import './Colors.scss';
@import './Generics.scss';

.order-in-progress {
  background: $color-green_light;
  width: 100%;
  color: $color-white;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  p {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    padding: 6px 0;
  }

  &-icon {
    width: 43px;
    height: 43px;
    background: $color-white;
    position: absolute;
    bottom: -6px;
    right: 10px;
    border-radius: 50%;
    border: 3px solid $color-green_light;
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: $desktop-media-width) {
    width: auto;
    border-radius: 10px 0px 0px 10px;
    margin-right: -20px;
    margin-left: 20px;

    p {
      padding: 6px 70px 6px 12px;
    }
  }
}
