@import './Colors.scss';

.order_failed {
  &-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
  }

  &-fail_icon {
    color: $color-red_notification;
    & > svg {
      font-size: 120px;
    }
  }

  &-title {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: $color-primary;
  }

  &-subtitle {
    padding: 10px;
    font-size: 16px;
    color: $color-primary;
  }

  &-button {
    background-color: $color-secondary;
    box-shadow: 0 0 12px 0px rgba(0,0,0,0.25098);
    height: 60px;
    padding: 0px 20px;
    -webkit-user-select: none;
    color: $color-white;
    margin: 10px;
    border-radius: 10px;
    -moz-user-select: none;
         user-select: none;
    cursor: pointer;
    font-size: 18px;

    &.button_cancel {
      font-size: 16px;
      background-color: $color-gray;
      color: $color-primary;
    }
  }

  &-bar_button {
    display: flex;
    align-items: center;
    height: 100%;

    & > svg {
      font-size: 35px;
    }

    span {
      margin-left: 10px;
      font-weight: 500;
      width: 100%;
    }
  }
  &-footer {
    font-size: 14px;
    text-align: center;

    &-link {
      font-weight: bold;
      color: $color-secondary;
    }
  }
}
