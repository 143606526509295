@import './Colors.scss';

@keyframes boxError {
  0% {
    background-color: $color-gray_light;
  }
  50% {
    background-color: $color-warning;
  }
  100% {
    background-color: $color-gray_light;
  }
}

