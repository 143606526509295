@import './Colors.scss';

.card {
  background-color: $color-gray_light;
  color: $color-primary_dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  &[disabled] {
    color: $color-gray_dark;
  }
  &.animateBoxError {
    animation: boxError 1s ease;
  }

  &-title {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    &--required {
      margin-left: 5px;
      color: $color-primary_light;
      font-size: 10px;
      text-align: center;
      margin-right: 5px;
      color: $color-warning;
    }
  }

  &-subtitle {
    margin-left: 5px;
    font-weight: bold;
    color: var(--color-component);
    font-size: 14px;
    text-align: center;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 5px 5px 5px;

    &[box_error='true'] {
      textarea.on-error,
      .select.on-error,
      input.on-error {
        border-color: $color-warning;
      }
    }
  }
}
