@import './Colors.scss';
@import './Generics.scss';

$tab_bar-height: 45px;

.tab_bar {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  height: $tab_bar-height;
  width: 100%;
  overscroll-behavior: none;
  grid-column: 1 / 4;
  padding: 0px 15px;
  &::-webkit-scrollbar {
    display: none;
  }

  &-item {
    padding: 0px 10px;
    margin: 10px 0px;
    font-size: 14px;
    color: var(--color-primary-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    white-space: nowrap;
    background-color: transparent;
    border-right: 1px solid;
    &[current-section='true'] {
      font-weight: 500;
      &::after {
        content: '';
        height: 2px;
        width: 25px;
        background-color: $color-primary;
      }
    }
  }

  &-more {
    height: $tab_bar-height;
    display: flex;
    align-items: center;
    font-size: 24px;
    width: 30px;
    position: fixed;
    cursor: pointer;

    &.right:hover,
    &.left:hover {
      background: $color-gray_light;
    }

    &.hidden {
      display: none;
    }

    &.left {
      background: linear-gradient(90deg, $color-white, $color-white, rgba(255,255,255,0));
      justify-content: flex-start;
      left: 0px;
    }
    &.right {
      background: linear-gradient(-90deg, $color-white, $color-white, rgba(255,255,255,0));
      justify-content: flex-end;
      right: 0px;
    }
  }
}

@media only screen and (min-width: $desktop-media-width) {
  .tab_bar {
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    &::before {
      content: 'Menú';
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
    &-item {
      font-size: 14px;
      font-weight: 500;
      flex-direction: row;
      border-right: none;
      border-radius: 10px;
      padding: 0px;
      cursor: pointer;
      margin: 5px 0px;

      & > span {
        width: 100%;
      }
      &:hover {
        background-color: $color-gray_light;
      }
      &::before {
        content: '';
        visibility: hidden;
        height: 35px;
        width: 18px;
        border-radius: 9px;
        background-color: var(--color-component);
      }
      &::after {
        display: none;
      }
      &[current-section='true'] {
        background-color: $color-gray_light;
        &::before {
          visibility: inherit;
        }
        &::after {
          display: none;
        }
      }
    }

    &-more {
      font-size: 24px;
      width: 100%;
      height: 40px;
      position: absolute;

      &.left {
        background: linear-gradient(180deg, $color-white, $color-white, rgba(255,255,255,0));
        justify-content: center;
        top: 180px;
      }
      &.right {
        background: linear-gradient(0deg, $color-white, $color-white, rgba(255,255,255,0));
        justify-content: center;
        bottom: 50px;
      }
    }
  }
}
