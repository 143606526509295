@import './Colors.scss';

.app-footer {
  width: 100%;
  height: 35px;
  background-color: var(--color-primary-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 5px;
    color: $color-white;
    font-size: 14px;
    font-style: italic;
  }
  img {
    height: 12px;
    width: auto;
  }
}
