@import './Colors.scss';

.line_checkbox {
  width: 100%;
  display: flex;
  height: 35px;
  justify-content: space-between;
  padding: 5px 15px;

  &-label {
    width: 100%;
    padding-right: 10px;
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--text_disabled {
      color: $color-gray_dark;
    }

    &--price {
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}
