@import 'Styles/Colors.scss';

.order_status_page {
  width: 100%;
  height: calc(100% - 35px);
  overflow-y: auto;

  &-wrap {
    padding: 0 20px;
  }

  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $color-primary_dark;
    margin-top: 30px;
    text-align: center;
  }
  &-description {
    font-size: 16px;
    line-height: 22px;
    color: $color-primary_dark;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }
  &-carousel {
    width: 90%;
    margin: 0 auto;

    .slick-prev:before,
    .slick-next:before {
      color: var(--color-primary-dark);
    }

    &-slider {
      margin-top: 20px;
    }

    &-item {
      padding: 5px 20px;

      cursor: pointer;

      svg.unactive {
        color: var(--color-component);
      }

      svg.active {
        color: var(--color-white);
      }
    }

    .slick-slide:first-of-type {
      .order_status_page-carousel-item {
        padding-left: 5px;
      }
    }

    .slick-slide {
      border-right: 1px solid var(--color-primary-dark);
    }

    .slick-slide:last-of-type {
      border-right: none;
    }

    .item-active {
      p {
        color: var(--color-component);
        font-weight: 600;
      }

      
      .order_status_page-carousel-icon {
        background-color: var(--color-component);
        
        
      }
    }

    &-label {
      font-size: 16px;
      color: var(--color-primary-dark);
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-gray_light;
      margin-right: 8px;
    }

    &-active-bar {
      width: 30px;
      height: 2px;
      background-color: var(--color-component);
      margin: 3px auto 0 auto;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    color: $color-primary_dark;
    margin-top: 20px;
  }
  &-date {
    margin-top: 3px;
    display: flex;
    -moz-column-gap: 10px;
         column-gap: 10px;
    color: $color-primary_dark;
  }
  &-status {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &-item {
      display: flex;
      align-items: center;
      row-gap: 20px;
      &-icon-container {
        width: 40px;
        height: 40px;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.active{
            color: var(--color-white);
          }
          svg.done{
            color: var(--color-white);
          }
          svg.pending{
            color: var(--color-component);
          }
        }
      }
      &-description {
        font-size: 14px;
      }
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 30px;
    margin-bottom: 30px;

    svg {
      width: 30px;
      height: 30px;
    }

    &-main {
      border-radius: 6px;
      border: 1px solid var(--color-component);
      background: var(--color-component);
      font-size: 18px;
      font-weight: 600;
      color: $color-white;
      padding: 10px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-secondary {
      border-radius: 6px;
      border: 1px solid var(--color-component);
      background: $color-white;
      font-size: 18px;
      font-weight: 600;
      color: var(--color-primary-dark);
      padding: 10px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .step-active {
    .order_status_page-status-item {
      &-icon-container {
        &-icon {
          width: 40px;
          height: 40px;
          background-color: var(--color-component);
        }
      }
      &-description {
        font-size: 16px;
        color: var(--color-component);
        font-weight: 600;
      }
    }
  }
  .step-done {
    .order_status_page-status-item {
      &-icon-container {
        &-icon {
          background-color: var(--color-primary-dark);
        }
      }
      &-description {
        color: $color-primary;
      }
    }
  }
  .step-pending {
    .order_status_page-status-item {
      &-icon-container {
        &-icon {
          background-color: $color-gray_light;
        }
      }
      &-description {
        color: $color-gray_medium;
      }
    }
  }

  .bottom-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
