@import './Colors.scss';

.delivery_page {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  overscroll-behavior: none;

  & > * {
    flex-shrink: 0;
    margin: 5px 0px;
  }

  align-items: center;
  padding: 30px 20px 120px 20px;

  &-title {
    color: $color-primary;
    font-weight: bold;
    padding-left: 20px;
    font-size: 24px;
    margin-bottom: 10px;
  }

  &-main_div {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overscroll-behavior: none;
    background-color: $color-white;
  }

  &-bottom_bar {
    background-color: transparent;
    justify-content: space-around;
  }

  &-area {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    & span {
      text-align: center;
      font-size: 14px;
      white-space: pre-line;
    }
  }

  &-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & span {
      font-weight: normal;
      text-align: left;
      font-size: 16px;
      white-space: pre-line;
    }
  }
  &-km_address-container {
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
         column-gap: 10px;
    margin-bottom: 15px;
  }
  &-km_address {
    font-size: 14px;
    text-align: center;
  }
  &-km_address-error {
    font-size: 14px;
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
    text-align: center;
  }
}

.share-position_button {
  padding: 5px 20px;
  background-color: var(--color-component);
  border: none;
  color: $color-white;
  margin-bottom: 0;

  & > svg {
    font-size: 20px;
    color: $color-white;
  }

  & > div {
    font-size: 14px;
  }
}

.large_button {
  padding: 10px;
}

.error_button {
  border: 1px solid red;
}

.terms_text {
  font-size: smaller;
}

.terms_text-span {
  font-weight: bold;
}

.terms_text-a {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--color-component);
  font-weight: 900;
}

.coupon-input {
  display: flex;
  align-items: center;
  gap: 10px;

  button,
  input {
    margin: 0 !important;
  }
}

.order-details-detailed {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 7px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2 {
    color: var(--color-component);
    font-weight: 700;
    margin-left: 5px;
    text-align: center;
    font-size: 18px;
    margin-top: 5px;
  }
}

.coupon-status {
  margin-top: 10px;
  .row {
    display: flex;
    align-items: center;
  }
  .coupon-success-label {
    color: $color-green;
    font-weight: 600;
    margin-left: 4px;
  }
}

.stripe_card {
  width: 100%;
}
