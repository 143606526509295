@import './Colors.scss';

.accordion {
  &-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 0px;
    font-weight: bold;
    color: var(--color-component);
    font-size: 16px;
    text-align: center;
    &-text {
      font-weight: bold;
      -webkit-user-select: none;
      -moz-user-select: none;
           user-select: none;
    }
    &-icon {
      margin-left: 4px;
      font-weight: bold;
      width: 16px;
      height: 16px;
      align-items: center;
      display: flex;
      &.show {
        transform: rotate(180deg) translate(0, 12%);
      }
    }
  }

  &-content {
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
         user-select: none;
    &.show {
      display: block;
    }
  }
}
