@import './Colors.scss';

.register {
  &-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    max-height: 100%;
    background-color: $color-white;
  }

  &-success_icon {
    color: var(--color-component);
    font-size: 60px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid var(--color-component);
    border-radius: 100px;
  }

  &-order_number {
    font-size: 24px;
    font-weight: bold;
    color: $color-primary;
  }

  &-title {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: var(--color-primary-dark);
    text-align: center;
  }

  &-subtitle {
    padding: 10px;
    font-size: 20px;
    color: $color-primary;
  }

  &-message {
    padding: 10px;
    font-size: 20px;
    color: $color-secondary;
  }

  &-button {
    background-color: var(--color-component);
    height: 60px;
    padding: 0px 20px;
    -webkit-user-select: none;
    color: $color-white;
    margin: 10px;
    border-radius: 10px;
    -moz-user-select: none;
         user-select: none;
    cursor: pointer;
    font-size: 18px;

    &.button_cancel {
      font-size: 16px;
      background-color: $color-gray;
      color: $color-primary;
    }
  }

  &-bar_button {
    display: flex;
    align-items: center;
    height: 100%;

    & > svg {
      font-size: 35px;
    }

    span {
      font-weight: 500;
      width: 100%;
    }
  }
}
