@import 'Styles/Colors.scss';
@import './Generics.scss';



.title_multi {
    font-size: x-large;
    margin: 8px;
}


.branch_offices {

    &_screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        min-height: 100vh;
        background-color: $color-white;
        

        .desktop_header {
            display: none;

            @media (min-width: 1024px) {
                width: 100%;
                height: 60px;
                background-color: $color-primary;
                color: $color-white;
                position: fixed;
                display: flex;
                padding: 0px 20px;
                align-items: center;
                top: 0;
                &--logo {
                  display: flex;
                  align-items: baseline;
                  & > span {
                    font-weight: 500;
                    & > mark {
                      border-radius: 4px;
                      background-color: $color-secondary;
                      padding: 0px 3px;
                    }
                  }
                }
            }
        }
    }

    &_logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        box-sizing: border-box;
        margin: 15px 0px;

        @media (min-width: 1024px) {
            height: 130px;
        }
        

        & > img {
            display: inline-block;
            height: 100%;
            border-radius: 10px;
        }
    }

    &_header-list {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background-color: white;

        @media (min-width: 1024px) {
            position: static;
            height: auto;
            padding-top: 100px;
        }
    }

    &_text {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: $color-primary;
        margin-bottom: 15px;
    }

    &_search-bar {
        width: 75%;
        height: 35px;
        border: 1px solid $color-primary;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding-left: 5px;
        margin-bottom: 15px;
        & >input {
            width: 100%;
        }
    }
    
    &_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 150px 10px 40px 10px;
        width: 100%;
        overflow-y: auto;

        @media (min-width: 1024px) {
            padding: 10px;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            max-height: calc(100vh - 270px);
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        
        &::-webkit-scrollbar:vertical {
            width:10px;
        }
        
        &::-webkit-scrollbar-button:increment, &::-webkit-scrollbar-button {
            display: none;
        } 
        
        &::-webkit-scrollbar:horizontal {
            height: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 20px;
            border: 2px solid #f1f2f3;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;  
        }
    }
}

@media screen and (min-width: $tablet-media-width) {
    .branch_offices {

        &_search-bar {
            width: $tablet-media-width * 0.75;
        }
    }
}

.bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 35px;
    width: 100%;
    background-color: $color-primary;
    background: linear-gradient($color-primary, $color-primary_dark);


    &-footer {
      span {
        margin-left: 5px;
        color: $color-white;
        font-size: 12px;
        font-style: italic;
      }
      img {
        height: 12px;
        width: auto;
      }
    }

    @media (min-width: 1024px) {
        display: none !important;
    }
  
}
