@import './Colors.scss';

.info_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  color: $color-primary-dark;
  gap: 5px;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 170px - 55px);

  &-logo {
    height: 100px;
    border-radius: 10px;
    width: auto;
    -o-object-fit: contain;
       object-fit: contain;
  }

  &-title {
    font-size: 18px;
    font-weight: bold;
  }

  &-sub_title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }

  &-schedules {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;

    &-today {
      padding: 4px;
      border-radius: 6px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $color-gray_light;
      color: var(--color-component);
    }

    &-day {
      font-weight: bold;
      &-schedule {
        color: $color-primary;
      }
      &-closed {
        color: $color-warning;
      }
    }
  }

  &-social_networks {
    gap: 5px;
    display: flex;
  }

  &--icons {
    color: var(--color-component);
  }

  &-footer {
    width: 100%;
    height: 55px;
    padding: 0px 20px;
    display: flex;
    background-color: $color-primary-dark;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;

    & > span {
      font-size: 16px;
      font-weight: bold;
      color: $color-white;
      margin: 0px 10px;
    }

    & > img {
      height: 12px;
    width: auto;
    }


    &:hover {
      background-color: $color-primary_light;
    }
  }
}
